import React, { useEffect } from "react";
import './loader.css';


const Loader = ({ showModal }) => {
    let elem;
    useEffect(() => {
        elem = document.querySelector('#progress-bar-fill');
        let width = 1;
        const id = setInterval(frame, 40);
        function frame() {
            if (width >= 90) {
                clearInterval(id);
            } else {
                width++;
                elem.style.width = width + '%';
            }
        }
    }, [elem])
    return (
        <div className="start-page-container">
            <div className="start-page-content">
                <div className="loader-box">
                    <div className="logo">
                        <div className="logo-img">
                            <svg width="161" height="98" viewBox="0 0 161 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M79.0284 85.6675C69.3714 87.3118 64.2745 94.5743 62.9332 98H80.8649H81.0402V40.4482C79.0284 28.8007 70.5785 4.68385 52.8737 1.39517C35.1692 -1.89351 24.0367 1.39517 20.6835 3.4506C36.7787 3.4506 52.8737 19.894 58.9095 28.1158L64.6132 35.28L70.7075 45.08L73.5679 50.5995C74.3685 52.1445 75.055 53.7436 75.6219 55.3849L75.9415 56.3099C77.8353 61.7913 78.2062 67.6671 77.0165 73.3351L72.9929 58.9472C69.6397 47.2997 56.0929 25.6492 28.7311 32.2267C1.36927 38.804 -0.106114 61.0027 2.57641 71.2797C4.58832 64.4282 13.0383 50.3143 30.743 48.67C48.4475 47.0257 62.5818 52.92 62.9332 56.8918C60.0321 55.5696 56.8624 54.8706 53.6626 54.8523C51.8089 54.8417 49.939 55.0869 48.2815 55.8994C36.267 61.7899 35.5218 76.5506 36.7787 83.6121L44.8261 73.3351L48.85 69.2242L52.8737 67.1686C70.5785 62.2355 77.6871 77.4458 79.0284 85.6675Z" fill="url(#paint0_linear_53_2089)" />
                                <path d="M83.0247 85.6675C92.5507 87.3118 97.5782 94.5743 98.9012 98H81.2132H81.04V40.4482C83.0247 28.8007 91.3599 4.68385 108.824 1.39517C126.288 -1.89351 137.27 1.39517 140.577 3.4506C124.701 3.4506 108.824 19.894 102.871 28.1158L97.2443 35.28L91.2326 45.08L88.4164 50.5891C87.6232 52.1411 86.9433 53.7462 86.3822 55.3926L86.0719 56.3029C84.2015 61.7913 83.8351 67.6645 85.0093 73.3351L88.9784 58.9472C92.2861 47.2997 105.649 25.6492 132.639 32.2267C159.629 38.804 161.085 61.0027 158.438 71.2797C156.454 64.4282 148.119 50.3143 130.655 48.67C113.19 47.0257 99.248 52.92 98.9012 56.8918C101.755 55.5735 104.89 54.8706 108.045 54.8523C109.874 54.8417 111.719 55.0873 113.35 55.8973C125.205 61.7864 125.941 76.5497 124.701 83.6121L116.762 73.3351L112.793 69.2242L108.824 67.1686C91.3599 62.2355 84.3477 77.4458 83.0247 85.6675Z" fill="url(#paint1_linear_53_2089)" />
                                <defs>
                                    <linearGradient id="paint0_linear_53_2089" x1="161" y1="98" x2="100.114" y2="140.182" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#2CB2FF" />
                                        <stop offset="1" stopColor="#2C74FF" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_53_2089" x1="158.072" y1="98" x2="100.161" y2="139.562" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#2CB2FF" />
                                        <stop offset="1" stopColor="#2C74FF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className="logo-text">OASIS</div>
                        <div className="logo-loader">
                            <div className="progress-bar">
                                <span id="progress-bar-fill"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Loader;
