import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { HeaderComponent } from "Components/Header/HeaderComponent";
// import { PaymentIn } from "Components/Modal/PaymentIn/PaymentIn";
// import { PaymentOut } from "Components/Modal/PaymentOut/PaymentOut";
// import { Currency } from "Components/Modal/Currency/Currency";
// import { Bank } from "Components/Modal/Bank/Bank";
import { Transactions } from "Components/Modal/Transactions/Transactions";
// import { QR } from "Components/Modal/QR/QR";
import { CardPayment } from "Components/Modal/CardPayment/CardPayment";
import { BurgerMenu } from "Components/BurgerMenu/BurgerMenu";
import { AboutUs } from "Components/AboutUs/AboutUs";
import { FooterComponent } from "Components/Footer/FooterComponent";
// import WithdrawCrypt from "Components/Modal/WithdrawCrypt/WithdrawCrypt";
import Modal from "Components/Modal/ModalsContainer/Modal";
import { useDispatch, useSelector } from "react-redux";

const MainLayout = () => {

  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const toggleBurgerMenu = () => setShowBurgerMenu(!showBurgerMenu);

  const [showAboutUs, setShowAboutUs] = useState(false);
  const toggleAboutUs = () => setShowAboutUs(!showAboutUs);

  const [showBank, setShowBank] = useState(false);
  const toggleBank = () => {
    setShowBank(!showBank);
    // setshowPaymentIn(false);
    // setshowPaymentOut(false);
  };
  const [showCardPayment, setShowCardPayment] = useState(false);
  const toggleCardPayment = () => {
    setShowCardPayment(!showCardPayment);
    setShowBank(false);
  };
  const [showTransactions, setShowTransactions] = useState(false);
  const toggleTransactions = () => {
    setShowTransactions(!showTransactions);
    setShowCardPayment(false);
  };

  const { showModal } = useSelector(state => state.casinoReducer);
  if (showModal) {
    document.body.style.width = '100vw';
    document.body.style.position = 'fixed';
  } else {
    document.body.style.position = 'relative';
  }
  return (
    <div className="App">
      {showModal ? <Modal /> : null}
      <BurgerMenu
        showBurgerMenu={showBurgerMenu}
        toggleBurgerMenu={toggleBurgerMenu}
        toggleAboutUs={toggleAboutUs}
      />
      <AboutUs showAboutUs={showAboutUs} toggleAboutUs={toggleAboutUs} />
      <HeaderComponent />
      {/* <PaymentIn
        showPaymentIn={showPaymentIn}
        togglePaymentIn={togglePaymentIn}
        toggleBank={toggleBank}
        toggleCurrency={toggleCurrency}
      />
      <PaymentOut
        showPaymentOut={showPaymentOut}
        togglePaymentOut={togglePaymentOut}
        toggleBank={toggleBank}
        toggleCurrency={toggleWithdrawCrypt}
      /> */}
      {/* <WithdrawCrypt
        showWithdrawCrypt={showWithdrawCrypt}
        toggleWithdrawCrypt={toggleWithdrawCrypt}
        backToPrevious={() =>
          backToPrevious(setshowPaymentOut, setShowWithdrawCrypt)
        }
      /> */}
      {/* <Currency
        showCurrency={showCurrency}
        toggleCurrency={toggleCurrency}
        toggleQR={toggleQR}
        backToPrevious={() => backToPrevious(setshowPaymentIn, setShowCurrency)}
      /> */}
      {/* <Bank
        showBank={showBank}
        toggleBank={toggleBank}
        toggleCardPayment={toggleCardPayment}
        backToPrevious={() => backToPrevious(setshowPaymentIn, setShowBank)}
      /> */}
      {/* <Transactions
        showTransactions={showTransactions}
        toggleTransactions={toggleTransactions}
        backToPrevious={() =>
          backToPrevious(setShowCardPayment, setShowTransactions)
        }
      /> */}
      {/* <QR
        showQR={showQR}
        toggleQR={toggleQR}
        backToPrevious={() => backToPrevious(setShowCurrency, setShowQR)}
      /> */}
      {/* <CardPayment
        showCardPayment={showCardPayment}
        toggleCardPayment={toggleCardPayment}
        toggleTransactions={toggleTransactions}
        backToPrevious={() => backToPrevious(setShowBank, setShowCardPayment)}
      /> */}
      <main>
        <Outlet />
      </main>
      <FooterComponent />
    </div>
  );
};

export default MainLayout;
