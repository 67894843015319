import React from "react"
import { useDispatch, useSelector } from "react-redux";
import './gameFrame.css';
import { casinoAction } from "../../redux/slices/casinoSlice";
import useDecimalAdjust from "CustomHooks/useDecimalAdjust";

const GameFrame = () => {
    const { currency, user_api, gameUrl, showModal, language } = useSelector(state => state.casinoReducer);
    const dispatch = useDispatch();
    const editedBalance = useDecimalAdjust("floor", user_api.balance / 100, -2);
    if (gameUrl) {
        document.body.style.position = 'fixed';
    } else {
        document.body.style.position = 'relative';
    }
    return (
        <div className="game-frame-container">
            <div className="game-frame-content">
                {gameUrl ? (
                    <div className="frame-box">
                        <iframe className="frame" src={gameUrl}></iframe>
                    </div>
                ) : null}
                <div className="navigation">
                    <div className="back-to-previous" onClick={() => { dispatch(casinoAction.cancelGame()) }}>
                        <div className="back-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.62539 12L14.6254 6L16.0254 7.4L11.4254 12L16.0254 16.6L14.6254 18L8.62539 12Z" fill="white" />
                            </svg>
                        </div>
                        {language === 'rus' ? <div className="text">Вернуться обратно</div> : <div className="text">Back</div>}
                    </div>
                    <div className="balance">
                        <div className="current-balance">
                            {user_api.balance ? editedBalance : '0.00'} {currency === "RUB" ? "₽" : "$"}
                        </div>
                        <button className="withdraw-balance" onClick={() => { dispatch(casinoAction.setShowModal(!showModal)) }}>
                            <div className="withdraw-img">
                                <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.75" y="1.25" width="15.5" height="15.5" rx="7.75" fill="white" />
                                    <rect x="0.75" y="1.25" width="15.5" height="15.5" rx="7.75" stroke="white" strokeWidth="1.5" />
                                    <path d="M8.5 5.5L8.5 9M8.5 12.5L8.5 9M8.5 9L12 9M8.5 9L5 9" stroke="#2B82E0" strokeWidth="1.5" />
                                </svg>
                            </div>
                            {language === 'rus' ? <div className="withdraw-text">Пополнить <b>Банк</b></div> : <div className="withdraw-text">Deposit</div>}
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
};

export default GameFrame;
