import React from "react";
import './chatIcon.css';
import { useSelector } from "react-redux";

const ChatIcon = () => {
    const { language } = useSelector(state => state.casinoReducer);
    return (
        <div className="chat-icon-container">
            <div className="icon">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.6 15.4818H5.2V12.8818H15.6V15.4818ZM20.8 11.7H5.2V9.1H20.8V11.7ZM20.8 7.8H5.2V5.2H20.8V7.8ZM26 26V2.6C26 1.885 25.7454 1.27292 25.2362 0.76375C24.7271 0.254583 24.115 0 23.4 0H2.6C1.885 0 1.27292 0.254583 0.76375 0.76375C0.254583 1.27292 0 1.885 0 2.6V18.2C0 18.915 0.254583 19.5271 0.76375 20.0362C1.27292 20.5454 1.885 20.8 2.6 20.8H20.8L26 26ZM21.905 18.2H2.6V2.6H23.4V19.6625L21.905 18.2Z" fill="#F6F6F6" />
                </svg>
            </div>
            {language === 'rus' ?
                <div className="text">Чат</div>
                :
                <div className="text">Chat</div>
            }
        </div>
    )
};

export default ChatIcon;
