import React from "react";
import './history.css';

const Item = ({isDeclined, item }) => {
    let declinedClazz = isDeclined ? 'declined' : '';
    let datetime, summ, transaction_id, type, user;

    if (item) {
        ({ datetime, summ, transaction_id, type, user } = item);

    }
    const datetime_ = new Date(datetime);

    const date = datetime_.toLocaleDateString();
    const time = datetime_.toLocaleTimeString();

    return (
        <li className={`history-item-container ${declinedClazz}`}>
            <div className="sum-status-box">
                <div className="sum">{type === 'out' ? '-' : '+'} {summ}$</div>
                <div className="status">{type === 'out' ? 'Вывод' : 'Пополнение'} {isDeclined ? '(Отменён)' : null}</div>
            </div>
            <div className="time-date-box">
                <div className="time">{time}</div>
                <div className="date">{date}</div>
            </div>
        </li>
    )
};

export default Item;
