import React from "react";
import './withdraw.css';

const Item = ({ coinName, shortName, coinLogo, toWithdraw }) => {
    return (
        <li className="withdraw-item-container" onClick={() => {
            toWithdraw('withdrawSum')
        }}>
            <div className="coin-name">
                <div className="name">{coinName}</div>
                <div className="short-name">{shortName}</div>
            </div>
            <div className="coin-logo">{coinLogo}</div>
        </li>
    )
};

export default Item;
