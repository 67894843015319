import React from "react";
import './header.css';

const ModalHeader = ({ text, closeModal}) => {
    return (
        <div className="modal-header">
            <div className="text">{text}</div>
            <div className="exit-icon" onClick={closeModal}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z" fill="#999999" />
                </svg>
            </div>
        </div>
    )
};

export default ModalHeader;
