import React, { useState } from "react";
import './language.css';
import RUSFlag from '../../images/RUS-flag.png';
import USAFlag from '../../images/USA-flag.png';
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../redux/slices/casinoSlice";


const Language = () => {
    let clazz;
    let languageClazz;
    const [active, setActive] = useState(false);
    const dispatch = useDispatch();
    const { language } = useSelector(state => state.casinoReducer);
    // switch (language) {
    //     case 'rus':
    //         languageClazz = 'rus';
    //         break;
    //     case 'eng':
    //         languageClazz = 'eng';
    //         break;
    //     default:
    //         break;
    // }
    if (active) {
        clazz = 'active';
    }
    return (
        <div className="language-container">
            <div className={`btn-box ${clazz}`} onClick={() => setActive(!active)}>
                {language === 'rus' ?
                    <div className="current-box" onClick={() => dispatch(casinoAction.setLanguage('rus'))}>
                        <img src={RUSFlag} alt="flag" />
                        <div className="text">Русский</div>
                    </div>
                    :
                    <div className="current-box" onClick={() => dispatch(casinoAction.setLanguage('eng'))}>
                        <img src={USAFlag} alt="flag" />
                        <div className="text">English</div>
                    </div>
                }
                {language === 'eng' ?
                    <div className="hidden-box" onClick={() => dispatch(casinoAction.setLanguage('rus'))}>
                        < img src={RUSFlag} alt="flag" />
                        <div className="text">Русский</div>
                    </div>
                    :
                    <div className="hidden-box" onClick={() => dispatch(casinoAction.setLanguage('eng'))}>
                        <img src={USAFlag} alt="flag" />
                        <div className="text">English</div>
                    </div>
                }


            </div>
        </div>
    )
};

export default Language;
