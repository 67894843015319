import React, {useEffect} from "react";
import './gameList.css';
import AmigoGamimg from '../../images/Amigo-Gaming.png';
import Aviatrix from '../../images/Aviatrix.png';
import BFGames from '../../images/BFGames.png';
import BGaming from '../../images/BGaming.png';
import BelatraGames from '../../images/BelatraGames.png';
import Betsoft from '../../images/Betsoft.png';
import Boldplay from '../../images/Boldplay.png';
import CTGaming from '../../images/CTGaming.png';
import {useDispatch, useSelector} from "react-redux";
import {casinoAction} from "../../redux/slices/casinoSlice";

const Providers = () => {
    const images = [AmigoGamimg, Aviatrix, BFGames, BGaming, BelatraGames, Betsoft, Boldplay, CTGaming];
    const { language, providers } = useSelector(state => state.casinoReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(casinoAction.getProviders())
    },[])

    return (
        <li className="provider-list-container">
            <div className="title-box">
                {language === 'rus' ?
                    <div className="filter-name">Провайдеры</div>
                    :
                    <div className="filter-name">Providers</div>
                }
            </div>
            <div className="provider-boxes">
                {
                    providers.map((item, index) => {
                        return (
                            <div className="provider"
                                style={{ "backgroundImage": `url('${item.img_url}'), linear-gradient(360deg, #393939 0%, rgba(55, 55, 55, 0.85) 99.68%),linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))` }}
                                key={index} onClick={() => {
                                dispatch(casinoAction.setProvider(item.name));
                                dispatch(casinoAction.setCategory(''));
                                dispatch(casinoAction.setSearchGame(''))}}>
                            </div>)
                    })
                }
            </div>
            <div className="show-more-container">
                <button className="show-more">
                    <div className="icon">
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.167 13.8333L13.0003 12.6458L9.16699 16.4792L5.33366 12.6458L4.16699 13.8333L9.16699 18.8333L14.167 13.8333ZM15.8337 2.16666L12.5003 2.16666C11.3475 2.16666 10.3649 2.57291 9.55241 3.38541C8.73991 4.19791 8.33366 5.18055 8.33366 6.33332L8.33366 11.4792L5.33366 8.47916L4.16699 9.66666L9.16699 14.6667L14.167 9.66666L13.0003 8.47916L10.0003 11.4792L10.0003 6.33332C10.0003 5.63888 10.2434 5.0486 10.7295 4.56249C11.2156 4.07638 11.8059 3.83332 12.5003 3.83332L15.8337 3.83332L15.8337 2.16666Z" fill="white" />
                        </svg>
                    </div>
                    {language === 'rus' ?
                        <div className="text">Bce провайдеры</div>
                        :
                        <div className="text">All providers</div>
                    }
                </button>
            </div>
        </li>
    )
};

export default Providers;
