import {axiosService} from "./axiosService";
import {urls} from "../config/urls/urls";

const apiService = {
    getAllGames: (page) => axiosService.get(urls.getAllGames(page)),
    startGame: (game_uui, player_id, session_id, tg_id) => axiosService.get(urls.startGame(game_uui, player_id, session_id, tg_id)),
    startDemo: (game_uui, player_id, session_id, tg_id) => axiosService.get(urls.startDemo(game_uui, player_id, session_id, tg_id)),
    createUser: (tg_chat_id) => axiosService.post(urls.createOrGetUser(), tg_chat_id),
    getUser: (tg_chat_id) => axiosService.get(urls.createOrGetUser(tg_chat_id)),
    cryptoOut: (user_id, amount, address) => axiosService.post(urls.cryptoOut(user_id, amount, address)),
    cryptoIn: (user_id, amount, currency, network) => axiosService.get(urls.cryptoIn(user_id, amount, currency, network)),
    getHistory: (user_id) => axiosService.get(urls.getHistory(user_id)),
    getBanners: () => axiosService.get(urls.getBanners()),
    getProviders: () => axiosService.get(urls.getProviders()),
}

export {apiService}