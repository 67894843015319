import React from "react";
import './withdraw.css';
import Item from "./Item";
import { ReactComponent as TetherRed } from "../../../../images/icons/tether-red-logo.svg";
import { ReactComponent as Tether } from "../../../../images/icons/tether-normal-logo.svg";
import { ReactComponent as TON } from "../../../../images/icons/ton-logo.svg";
import { ReactComponent as BTC } from "../../../../images/icons/btc-logo.svg";
import { ReactComponent as BCH } from "../../../../images/icons/bch-logo.svg";
import { useSelector } from "react-redux";

const WithdrawList = ({ toWithdraw }) => {
    const { language } = useSelector(state => state.casinoReducer);
    return (
        <ul className="withdraw-list">
            {language === 'rus' ? <div className="hint">Выбери валюту</div> : <div className="hint">Choose coin</div>}
            <div className="item-box">
                <Item coinName={'USDT'} shortName={'Tron'} coinLogo={<TetherRed />} toWithdraw={toWithdraw} />
                {/*<Item coinName={'BTC'} shortName={'Bitcoin'} coinLogo={<BTC />} toWithdraw={toWithdraw} />*/}
                {/*<Item coinName={'TON'} shortName={'Toncoin'} coinLogo={<TON />} toWithdraw={toWithdraw} />*/}
                {/*<Item coinName={'BCH'} shortName={'Bitcoin Cash'} coinLogo={<BCH />} toWithdraw={toWithdraw} />*/}
                {/*<Item coinName={'USDT (ERC20)'} shortName={'Ethereum'} coinLogo={<Tether toWithdraw={toWithdraw} />} />*/}
            </div>
        </ul>
    )
};

export default WithdrawList;