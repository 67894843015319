import React, { useState } from "react";
import './footer.css';
import { useDispatch, useSelector } from "react-redux";
import Language from "Components/Language/Language";
import { ReactComponent as OasisIcon } from "../../images/icons/oasis-logo.svg";
import { ReactComponent as MailIcon } from "../../images/icons/mail.svg";
import { ReactComponent as CinCircleIcon } from "../../images/icons/cInCircle.svg";
import { casinoAction } from "../../redux/slices/casinoSlice";
import RUSFlag from '../../images/RUS-flag.png';
import USAFlag from '../../images/USA-flag.png';


const FooterComponent = () => {
    const { language } = useSelector(state => state.casinoReducer);
    const [active, setActive] = useState(false);
    const dispatch = useDispatch();
    return (
        <>
            <div className="footer-for-phone">
                <hr />
                <div className="title-box">
                    <div className="icon"><OasisIcon /></div>
                    <div className="title">Oasis</div>
                </div>
                <div className="nav-box">
                    {language === 'rus' ?
                        <aside className='column'>
                            <div>O нас</div>
                            <div>Новости</div>
                            <div>Правила и условия</div>
                            <div>Лицензия</div>
                            <div>Антиблокировка</div>
                        </aside>
                        :
                        <aside className='column'>
                            <div>About Us</div>
                            <div>News</div>
                            <div>Rules and terms</div>
                            <div>License</div>
                            <div>Antiblock</div>
                        </aside>
                    }
                    <div className="column">
                        <div className="language-box">
                            <div className={`btn-box ${active ? 'active' : null}`} onClick={() => setActive(!active)}>
                                {language === 'rus' ?
                                    <div className="current-box" onClick={() => dispatch(casinoAction.setLanguage('rus'))}>
                                        <img src={RUSFlag} alt="flag" />
                                        <div className="text">Русский</div>
                                    </div>
                                    :
                                    <div className="current-box" onClick={() => dispatch(casinoAction.setLanguage('eng'))}>
                                        <img src={USAFlag} alt="flag" />
                                        <div className="text">English</div>
                                    </div>
                                }
                                {language === 'eng' ?
                                    <div className="hidden-box" onClick={() => dispatch(casinoAction.setLanguage('rus'))}>
                                        < img src={RUSFlag} alt="flag" />
                                        <div className="text">Русский</div>
                                    </div>
                                    :
                                    <div className="hidden-box" onClick={() => dispatch(casinoAction.setLanguage('eng'))}>
                                        <img src={USAFlag} alt="flag" />
                                        <div className="text">English</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text">
                    Oasis casino управляется компанией Oasis
                    <br></br>зарегистрированной в соответствии с
                    <br></br> законодательством Кюросао под номерос
                    <br></br>****** по адресу: Abraham Mendez
                    <br></br> Chumaceiro Boulevard 03, Curacao. Этот вед-
                    <br></br>сайт лицензируется и регулируется
                    <br></br>Antillephone N.V. Лицензия №.
                    <br></br> ****/***2024-***
                </div>
                <div className="other-info-box">
                    <div className="copy-right-text">
                        <div className="icon"><CinCircleIcon /></div>
                        <div className="text">2024 Oasis | Все права защищены.</div>
                    </div>
                    <div className="help-box">
                        <div className="icon"><MailIcon /></div>
                        <div className="text">
                            <b>Поддержка</b>
                            <br></br>support@dammn123.com
                        </div>
                    </div>
                </div>
            </div>
            <footer className='main-footer'>
                <div className='footer-btns'>
                    {language === 'rus' ?
                        <button className='payment-system'>платёжные системы</button>
                        :
                        <button className='payment-system'>payment systems</button>
                    }
                    {/* <button className={css.language}>
                    <img src={RUSFlag} alt="flag" />
                    <div className={css.text}>Язык</div>
                </button> */}
                    <Language />
                </div>
                <div className='info'>
                    {language === 'rus' ?
                        <aside className='footer-navigation'>
                            <div>O нас</div>
                            <div>Новости</div>
                            <div>Правила и условия</div>
                            <div>Лицензия</div>
                            <div>Антиблокировка</div>
                        </aside>
                        :
                        <aside className='footer-navigation'>
                            <div>About Us</div>
                            <div>News</div>
                            <div>Rules and terms</div>
                            <div>License</div>
                            <div>Antiblock</div>
                        </aside>
                    }
                    {language === 'rus' ?
                        <article className='content-text'>
                            damn 123 — это популярная площадка c автоматами лучших провайдеров. Заведение обеспечивает честное
                            проведение игровых сессий, псевдо-безопасность финансовых операций и своевременность выплаты ваших денег на счёт владельца казино.
                            <br></br>
                            Для внесения депозита предлагаются около десяти безнадежных способов. Деньги мгновенно поступают на счет.<br></br>
                            Ставить их на раунды можно круглосуточно. Средний уровень теоретической отдачи по залу составляет 496%, что является ебанутой суммой.B некоторых версиях азартных
                            игр этот показатель достигает 995%.
                            <p>
                                Для зарегистрированных пользователей в казино проводятся турниры c большим призовым фондом и лотереи c безценными
                                призами (такими как время проведённое вместе c нами). C официального сайта можно скачать приложение для компьютера или мобильного устройства. После установки
                                программы для посещения зала пользоваться браузером не понадобится. Вход будет открываться c рабочего стола ПК и
                                дисплея телефона.
                            </p>
                        </article>
                        :
                        <article className='content-text'>
                            damn 123 - is a popular platform with top-tier slot machines from leading providers. The establishment ensures fair gaming sessions,
                            pseudo-financial transaction security, and timely payouts to the casino owner's account.
                            <br></br>
                            There are about ten hopeless methods offered for making deposits, with funds instantly credited to the account.
                            Bets can be placed on rounds round-the-clock. The average theoretical return rate for the hall is 496%,
                            which is an insane amount. In some versions of gambling games, this figure reaches 995%.
                            <p>
                                For registered users, the casino hosts tournaments with a large prize pool and lotteries with priceless prizes (such as time spent together with us).
                                An application for computer or mobile devices can be downloaded from the official website. After installing the program,
                                there's no need to use a browser to access the hall. Access will be available from the PC desktop and phone display.
                            </p>
                        </article>
                    }
                </div>
                <div className='other-nav'>
                    <div className='adaptive-row'>
                        <div className='row-with-icon'>
                            <div className='icon'>
                                <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V11C0 11.5304 0.210714 12.0391 0.585786 12.4142C0.960859 12.7893 1.46957 13 2 13H16C16.5304 13 17.0391 12.7893 17.4142 12.4142C17.7893 12.0391 18 11.5304 18 11V2C18 1.46957 17.7893 0.960859 17.4142 0.585786C17.0391 0.210714 16.5304 0 16 0ZM6.316 7.316L7.918 8.716C8.223 8.982 8.609 9.114 9 9.114C9.391 9.114 9.777 8.981 10.082 8.716L11.684 7.316L11.647 7.353L15.293 10.999H2.707L6.353 7.353L6.316 7.316ZM2 10.293V3.54L5.602 6.691L2 10.293ZM12.398 6.691L16 3.54V10.29L12.398 6.691ZM16 2V2.21L9.424 7.964C9.30356 8.06006 9.15406 8.11238 9 8.11238C8.84594 8.11238 8.69644 8.06006 8.576 7.964L2 2.21V2H16Z" fill="#F8F8F8" />
                                </svg>
                            </div>
                            <div className='text'>support@dammn123.com</div>
                        </div>
                        <div className='row-with-icon'>
                            <div className='icon'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8" cy="8" r="7.5" stroke="white" />
                                    <path d="M5.336 8.248C5.336 7.768 5.42133 7.336 5.592 6.952C5.76267 6.568 5.98933 6.25867 6.272 6.024C6.55467 5.784 6.86933 5.60267 7.216 5.48C7.56267 5.35733 7.92533 5.296 8.304 5.296C8.87467 5.296 9.40533 5.41867 9.896 5.664V6.552C9.496 6.27467 9.016 6.136 8.456 6.136C7.864 6.136 7.352 6.31467 6.92 6.672C6.488 7.02933 6.26933 7.55467 6.264 8.248C6.264 8.68533 6.376 9.06133 6.6 9.376C6.824 9.69067 7.09867 9.91733 7.424 10.056C7.74933 10.1947 8.09867 10.264 8.472 10.264C9.06933 10.264 9.55733 10.12 9.936 9.832V10.736C9.43467 10.9813 8.89333 11.104 8.312 11.104C7.93867 11.104 7.57867 11.0453 7.232 10.928C6.88533 10.8053 6.568 10.6293 6.28 10.4C5.992 10.1707 5.76267 9.86933 5.592 9.496C5.42133 9.12267 5.336 8.70667 5.336 8.248Z" fill="white" />
                                </svg>
                            </div>
                            {language === 'rus' ?
                                <div className='text'>damn 123 - онлайн казино</div>
                                :
                                <div className='text'>damn 123 - online casion</div>
                            }
                        </div>
                    </div>
                    <div className='adaptive-row'>
                        <div className='row-underline'>
                            {language === 'rus' ?
                                <div className='text'>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</div>
                                :
                                <div className='text'>PRIVACY POLICY</div>
                            }
                        </div>
                        <div className='row-underline'>
                            {language === 'rus' ?
                                <div className='text'>ОНЛАЙН - ПОМОЩНИК</div>
                                :
                                <div className='text'>ONLINE - HELPER</div>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export { FooterComponent }