import React, { useState } from "react";
import './modal.css';
import ModalHeader from "../ModalsComponents/Header/Header";
import Navigation from "../ModalsComponents/Navigation/Navigation";
import DepositList from "../ModalsComponents/DepositList/DepositList";
import WithdrawList from "../ModalsComponents/WithdrawList/WithdrawList";
import History from "../ModalsComponents/History/History";
import ChoosePaymentSum from "../ModalsComponents/ChoosePaymentSum/ChosePaymentSum";
import ChooseWithdrawSum from "../ModalsComponents/ChooseWithdrawSum/ChooseWithdrawSum";
import { casinoAction } from "../../../redux/slices/casinoSlice";
import { useDispatch, useSelector } from "react-redux";
// import Status from "../ModalsComponents/Status/Status";

const Modal = () => {
    const [showCurrentList, setShowCurrentList] = useState('deposit');
    const { showModal, language } = useSelector(state => state.casinoReducer);
    const dispatch = useDispatch();
    return (
        <div className="modal-container">
            <div className="modal-content">
                <ModalHeader text={language === 'rus' ? 'Баланс' : 'Balance'} closeModal={() => { dispatch(casinoAction.setShowModal(!showModal)) }} />
                {/* <ModalHeader text={'Подтверждение'} closeModal={closeModal} />
                <Status currentStatus={'completed'} /> */}
                <Navigation swapList={setShowCurrentList} currentList={showCurrentList}/>
                {showCurrentList === 'deposit' ? <DepositList toPay={setShowCurrentList} /> : null}
                {showCurrentList === 'withdraw' ? <WithdrawList toWithdraw={setShowCurrentList} /> : null}
                {showCurrentList === 'history' ? <History deposit={setShowCurrentList}/> : null}
                {showCurrentList === 'paymentSum' ? <ChoosePaymentSum /> : null}
                {showCurrentList === 'withdrawSum' ? <ChooseWithdrawSum /> : null}
            </div>
        </div>
    )
};

export default Modal;
