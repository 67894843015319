import React from "react";
import './gameList.css';
import Item from "./Item";
import { ReactComponent as PopularIcon } from "../../images/icons/popular-icon.svg";
import { ReactComponent as FastGameIcon } from "../../images/icons/fast-games-icon.svg";
import { ReactComponent as SlotsIcon } from "../../images/icons/slots-icon.svg";
import { ReactComponent as LobbyIcon } from "../../images/icons/lobby-icon.svg";
import { ReactComponent as CardsIcon } from "../../images/icons/cards-icon.svg";
import Providers from "./Providers";
import {useSelector} from "react-redux";

const GameList = () => {
    const {searchGame, language, filterProvider, filterCategory} = useSelector(state => state.casinoReducer)
    return (
        <ul className="main-game-list">
            {filterCategory ? <Item filterName={language === 'rus' ? 'Результат поиска по категории' : 'Search result by category'}/> : null}
            {filterProvider ? <Item filterName={language === 'rus' ? 'Игры провайдера' : 'Provider games'}/> : null}
            {searchGame ? <Item filterName={language === 'rus' ? 'Результат поиска' : 'Searching results'}/> : null}
            <Item filterIcon={<PopularIcon/>} filterName={language === 'rus' ? 'Самые Популярные' : 'Popular'}/>
            <Item filterIcon={<FastGameIcon/>} filterName={language === 'rus' ? 'Быстрые игры' : 'Fast games'}/>
            <Item filterIcon={<SlotsIcon/>} filterName={language === 'rus' ? 'Слоты' : 'Slots'}/>
            <Item filterIcon={<LobbyIcon/>} filterName={language === 'rus' ? 'Лобби' : 'Lobby'}/>
            <Item filterIcon={<CardsIcon/>} filterName={language === 'rus' ? 'Карточные' : 'Cards'}/>
            <Providers/>

        </ul>
    )
};

export default GameList;
