import React from "react";
import './history.css';
import { useSelector } from "react-redux";

const Nothing = ({ deposit }) => {
    const { language } = useSelector(state => state.casinoReducer);
    return (
        <div className="nothing-box">
            {language === 'rus' ?
                <div className="text">Тут пока ничего нет</div>
                :
                <div className="text">Nothing here</div>
            }
            {language === 'rus' ?
                <button className="deposit" onClick={() => deposit('deposit')}>Пополнить</button>
                :
                <button className="deposit" onClick={() => deposit('deposit')}>Deposit</button>
            }
        </div>
    )
};

export default Nothing;
