import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiService } from "../../services/apiService";


const initialState = {
    allGames: [],
    user_api: {},
    // user_api: {
    //     user_id: 2,
    //     balance: 99999999999.9,
    //     reg_date: "2024-01-11",
    //     tg_chat_id: "687899499"
    // },
    isError: false,
    isLoading: false,
    currency: null,
    network: null,
    errorText: null,
    history: [],
    searchGame: null,
    language: 'rus',
    gameUrl: '',
    banners: [],
    showModal: false,
    providers: [],
    filterProvider: '',
    filterCategory: '',
};

const getAllGames = createAsyncThunk(
    'casinoSlice/getAllGames',
    async (page, thunkAPI) => {
        try {
            const { data } = await apiService.getAllGames(page)
            return data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const startGame = createAsyncThunk(
    'casinoSlice/startGame',
    async ([game_uui, player_id, session_id, tg_id], thunkAPI) => {
        try {
            const { data } = await apiService.startGame(game_uui, player_id, session_id, tg_id)
            // window.location.href = data.data;
            return data.data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const startDemo = createAsyncThunk(
    'casinoSlice/startDemo',
    async ([game_uui, player_id, session_id, tg_id], thunkAPI) => {
        try {
            const { data } = await apiService.startDemo(game_uui, player_id, session_id, tg_id)
            // window.location.href = data.data;
            return data.data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const auth = createAsyncThunk(
    'casinoSlice/auth',
    async (tg_chat_id, thunkApi) => {
        try {
            await apiService.createUser(tg_chat_id);

            const getUserResponse = await apiService.getUser(tg_chat_id.tg_chat_id);
            return getUserResponse.data[0];

        } catch (error) {
            const getUserResponse = await apiService.getUser(tg_chat_id.tg_chat_id);
            return getUserResponse.data[0];

        }
    }
);

const cryptoOut = createAsyncThunk(
    'casinoSlice/cryptoOut',
    async ([user_id, amount, address], thunkAPI) => {
        try {
            const { data } = await apiService.cryptoOut(user_id, amount, address)
            return data.url

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const cryptoIn = createAsyncThunk(
    'casinoSlice/cryptoIn',
    async ([user_id, amount, currency, network], thunkAPI) => {
        try {
            const { data } = await apiService.cryptoIn(user_id, amount, currency, network)
            window.location.href = data.url;
            return data.url

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const getHistory = createAsyncThunk(
    'casinoSlice/getHistory',
    async (user_id, thunkAPI) => {
        try {
            const { data } = await apiService.getHistory(user_id)
            return data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const getBanners = createAsyncThunk(
    'casinoSlice/getBanners',
    async (_, thunkAPI) => {
        try {
            const { data } = await apiService.getBanners()
            return data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)

const getProviders = createAsyncThunk(
    'casinoSlice/getProviders',
    async (_, thunkAPI) => {
        try {
            const { data } = await apiService.getProviders()
            return data

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data)
        }
    }
)


const casinoSlice = createSlice({
    name: 'casinoSlice',
    initialState,
    reducers: {
        cancelError: (state) => {
            state.isError = false
        },
        setCurrencyAndNetwork: (state, action) => {
            state.currency = action.payload.currency;
            state.network = action.payload.network;
            console.log(state.currency, state.network);
        },
        setSearchGame: (state, action) => {
            state.searchGame = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload
        },
        cancelGame: (state) => {
            state.gameUrl = ''
        },
        setProvider: (state, action) => {
            state.filterProvider = action.payload
        },
        setCategory: (state, action) => {
            state.filterCategory = action.payload
            console.log(state.filterCategory);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getAllGames.fulfilled, (state, action) => {
                state.isLoading = false;
                state.allGames = action.payload

            })
            .addCase(getAllGames.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllGames.rejected, (state) => {
                state.isLoading = false
            })

            .addCase(auth.fulfilled, (state, action) => {
                state.user_api = action.payload
            })
            .addCase(auth.rejected, (state, action) => {
                state.user_api = action.payload
            })

            .addCase(cryptoIn.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorText = action.payload;
            })
            .addCase(cryptoIn.fulfilled, (state) => {
                state.isError = false
                state.isLoading = true
            })
            .addCase(cryptoIn.pending, (state) => {
                state.isLoading = true
            })

            .addCase(cryptoOut.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false;
                state.errorText = action.payload;
            })
            .addCase(cryptoOut.fulfilled, (state) => {
                state.isError = false
                state.isLoading = false
            })
            .addCase(cryptoOut.pending, (state) => {
                state.isLoading = true
            })

            .addCase(startGame.pending, (state) => {
                state.isLoading = true
            })
            .addCase(startGame.fulfilled, (state, action) => {
                state.gameUrl = action.payload
                state.isLoading = false
            })
            .addCase(startGame.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(startDemo.pending, (state) => {
                state.isLoading = true
            })
            .addCase(startDemo.fulfilled, (state, action) => {
                state.gameUrl = action.payload
                state.isLoading = false
            })
            .addCase(startDemo.rejected, (state) => {
                state.isLoading = false
            })
            .addCase(getHistory.fulfilled, (state, action) => {
                state.history = action.payload
            })
            .addCase(getBanners.fulfilled, (state, action) => {
                state.banners = action.payload
            })
            .addCase(getProviders.fulfilled, (state, action) => {
                state.providers = action.payload
            })
    }
})


const { reducer: casinoReducer, actions } = casinoSlice;

const casinoAction = {
    ...actions,
    getAllGames,
    startGame,
    auth,
    cryptoOut,
    cryptoIn,
    startDemo,
    getHistory,
    getBanners,
    getProviders
}

export {
    casinoReducer,
    casinoAction
}
