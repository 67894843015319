import React, { useRef, useState } from "react";
import './chooseWithdrawSum.css';
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../../../redux/slices/casinoSlice";

const ChooseWithdrawSum = () => {
    const dispatch = useDispatch();

    const { user_api, isError, errorText, language } = useSelector(state => state.casinoReducer)

    let inputAmount = useRef('')
    let inputAddress = useRef('')

    const [inputAmountError, setInputAmountError] = useState(false);
    const [inputAddressError, setInputAddressError] = useState(false);

    const [loadFetch, setLoadFetch] = useState(false);

    const handleWithdraw = () => {

        if (!inputAddress.current.value) {
            setInputAddressError(true)
        }
        if (!inputAmount.current.value) {
            setInputAmountError(true)
        }

        if (inputAddress.current.value && inputAmount.current.value) {
            dispatch(
                casinoAction.cryptoOut([
                    user_api.user_id,
                    inputAmount.current.value,
                    inputAddress.current.value]
                )
            );
            inputAmount.current.value = ''
            inputAddress.current.value = ''
            setLoadFetch(true)
        }
    };
    return (
        <div className="choose-withdraw-sum-container">
            {language === 'rus' ?
                <div className="input-title">Сумма вывода</div>
                :
                <div className="input-title">Withdrawal Sum</div>
            }
            <div className="input-box">
                <input ref={inputAmount} type="number" placeholder={language === 'rus' ? "Минимум - 2$" : "Minimum - 2$"}
                    onChange={() => {
                        setInputAddressError(false);
                        setInputAmountError(false);
                        dispatch(casinoAction.cancelError())
                        setLoadFetch(false)
                    }} />
                <div className="coin">USDT</div>
            </div>
            {inputAmountError ?
                language === 'rus' ?
                    <div className='error'>Обязательное поле</div>
                    :
                    <div className='error'>This field is required</div>
                : null}
            {language === 'rus' ? <div className="input-title">Адресс</div> : <div className="input-title">Address</div>}
            <div className="input-box">
                <input ref={inputAddress} type="text" placeholder="USDT" onChange={() => {
                    setInputAddressError(false);
                    setInputAmountError(false);
                    dispatch(casinoAction.cancelError())
                    setLoadFetch(false)
                }} />
                <div className="coin">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.11133 6.18542C4.11133 5.63527 4.32987 5.10765 4.71889 4.71864C5.1079 4.32963 5.63551 4.11108 6.18566 4.11108H12.9259C13.1983 4.11108 13.468 4.16474 13.7197 4.26898C13.9714 4.37323 14.2 4.52602 14.3927 4.71864C14.5853 4.91126 14.7381 5.13993 14.8423 5.3916C14.9466 5.64327 15.0002 5.91301 15.0002 6.18542V12.9256C15.0002 13.198 14.9466 13.4678 14.8423 13.7195C14.7381 13.9711 14.5853 14.1998 14.3927 14.3924C14.2 14.585 13.9714 14.7378 13.7197 14.8421C13.468 14.9463 13.1983 15 12.9259 15H6.18566C5.91326 15 5.64352 14.9463 5.39185 14.8421C5.14018 14.7378 4.91151 14.585 4.71889 14.3924C4.52627 14.1998 4.37347 13.9711 4.26923 13.7195C4.16498 13.4678 4.11133 13.198 4.11133 12.9256V6.18542Z" stroke="#B8B8B8" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M1.81626 11.8889C1.56892 11.7503 1.36318 11.55 1.21985 11.3083C1.07653 11.0665 1.00069 10.7918 1 10.512V2.58534C1 1.7134 1.72593 1 2.61317 1H10.679C11.284 1 11.613 1.30518 11.8889 1.79267" stroke="#B8B8B8" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {inputAddressError ?
                language === 'rus' ?
                    <div className='error'>Обязательное поле</div>
                    :
                    <div className='error'>This field is required</div>
                : null}
            {language === 'rus' ?
                <div className="fee">Приблизительная комиссия - 0,2 $</div>
                :
                <div className="fee">Approximate commission - 0,2 $</div>
            }
            {language === 'rus' ?
                <button className="lets-pay" onClick={handleWithdraw}>Перейти к оплате</button>
                :
                <button className="lets-pay" onClick={handleWithdraw}>Let's pay</button>
            }
            {isError ? (
                <div className='error'>
                    {errorText.error === 'Not enough money to withdraw'
                        ? 'Недостаточно денег'
                        : 'Кошелёк не валидный'
                    }
                </div>
            ) : null}
            {isError ?
                null
                : loadFetch ?
                    language === 'rus' ?
                        <div className='loadText'>Вывод успешный</div>
                        :
                        <div className='loadText'>Withdrawal successful</div>
                    : null}
        </div>
    )
};

export default ChooseWithdrawSum;
