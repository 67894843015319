import React from "react";
import './anchor.css';
import { ReactComponent as ArrowTop } from "../../images/icons/arrow-top.svg";

const Anchor = () => {
    return (
        <div className="anchor-container">
            <div className="title">Вверх</div>
            <button className="anchor" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}><ArrowTop /></button>
        </div>
    )
};

export default Anchor;