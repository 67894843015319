import {combineReducers, configureStore} from "@reduxjs/toolkit";

import {casinoReducer} from "./slices/casinoSlice";



const rootReducer = combineReducers({
    casinoReducer
});

const setupStore = () => configureStore({
    reducer: rootReducer
})

export {
    setupStore
}