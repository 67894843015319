import React, { useEffect } from "react";
import './history.css';
import Item from "./Item";
import Nothing from "./Nothing";
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../../../redux/slices/casinoSlice";

const History = ({ deposit }) => {
    const dispatch = useDispatch();
    const { user_api, history } = useSelector(state => state.casinoReducer)

    useEffect(() => {
        dispatch(casinoAction.getHistory(user_api.user_id))
    }, [user_api, dispatch])
    return (
        <ul className="transaction-history-list">
            <div className="item-box">
                {/*<Item sum={'+ 1200$'} status={'Пополнение'} time={'4:19'} date={'17.02.2024'} isDeclined={false}/>*/}
                {/*<Item sum={'- 2400$'} status={'Вывод'} time={'4:20'} date={'17.02.2024'} isDeclined={false}/>*/}
                {/*<Item sum={'- 2400$'} status={'Вывод'} time={'4:20'} date={'17.02.2024'} isDeclined={true}/>*/}
                {
                    history.length > 0 ? history.map(value => <Item item={value} />) : <Nothing deposit={deposit}/>
                }
            </div>
        </ul>
    )
};

export default History;
