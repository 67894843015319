import React, { useEffect, useRef } from "react";
import css from "./header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../redux/slices/casinoSlice";
import useDecimalAdjust from "CustomHooks/useDecimalAdjust";
import { ReactComponent as OasisIcon } from "../../images/icons/oasis-logo.svg";
// import lottie from 'lottie-web';


const HeaderComponent = () => {
  const { user_api, currency, language, showModal } = useSelector(state => state.casinoReducer)
  const dispatch = useDispatch();
  const editedBalance = useDecimalAdjust("floor", user_api.balance / 100, -2);
  return (
    <header className={css.mainHeader}>
      <div className={css.logo}>
        <div className={css.logoImg}><OasisIcon /></div>
        <h1 className={css.logoTitle}>OASIS</h1>
      </div>
      {language === 'rus' ? <nav className={css.navigation}>
        <div>Игровой зал</div>
        <div>Турниры</div>
        <div>Бонусы</div>
        <div>Зал славы</div>
      </nav> :
        <nav className={css.navigation}>
          <div>Game hub</div>
          <div>Tournament</div>
          <div>Bonuses</div>
          <div>Hall of Fame</div>
        </nav>
      }
      {/* ().toFixed(2) */}
      <div className={css.balance}>
        <div className={css.currentBalance}>
          {user_api.balance ? editedBalance : '0.00'} {currency === "RUB" ? "₽" : "$"}
        </div>
        <button className={css.withdrawBalance} onClick={() => { dispatch(casinoAction.setShowModal(!showModal)) }}>
          <div className={css.withdrawImg}>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.75" y="1.25" width="15.5" height="15.5" rx="7.75" fill="white" />
              <rect x="0.75" y="1.25" width="15.5" height="15.5" rx="7.75" stroke="white" strokeWidth="1.5" />
              <path d="M8.5 5.5L8.5 9M8.5 12.5L8.5 9M8.5 9L12 9M8.5 9L5 9" stroke="#2B82E0" strokeWidth="1.5" />
            </svg>
          </div>
          {language === 'rus' ?
            <div className={css.withdrawText}>Пополнить <b>Банк</b></div>
            :
            <div className={css.withdrawText}>Deposit</div>
          }
        </button>
      </div>
    </header>
  );
};

export { HeaderComponent };
{/* <div className={css.burgerMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            onClick={toggleBurgerMenu}
          >
            <path d="M0 1H18" stroke="white" />
            <path d="M0 7H18" stroke="white" />
            <path d="M0 13H18" stroke="white" />
          </svg>
        </div>*/}




