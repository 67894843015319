import React, { useState } from "react";
import './gameList.css';
import Game from "./Game";
import { useSelector } from "react-redux";

const Item = ({ filterIcon, filterName }) => {
    const { language } = useSelector(state => state.casinoReducer);
    let filter_param;
    switch (filterName) {
        case 'Слоты':
            filter_param = 'slots';
            break;
        case 'Slots':
            filter_param = 'slots';
            break;
        case 'Лобби':
            filter_param = '';
            break;
        case 'Lobby':
            filter_param = '';
            break;
        case 'Карточные':
            filter_param = '';
            break;
        case 'Cards':
            filter_param = '';
            break;
        case 'Быстрые':
            filter_param = '';
            break;
        case 'Fast':
            filter_param = '';
            break;
        case 'Самые Популярные':
            filter_param = '';
            break;
        case 'Popular':
            filter_param = '';
            break;
        case 'Результат поиска':
            filter_param = 'search';
            break;
        case 'Searching results':
            filter_param = 'search';
            break;
        case 'Игры провайдера':
            filter_param = 'provider';
            break;
        case 'Provider games':
            filter_param = 'provider';
            break;
        case 'Результат поиска по категории':
            filter_param = 'categorySearch'
            break
        case 'Search result by category':
            filter_param = 'categorySearch'
            break
        default:
            break;
    }

    const { allGames, searchGame, filterProvider, filterCategory } = useSelector(state => state.casinoReducer);
    const [visibleGames, setVisibleGames] = useState(8);

    let filteredGames = allGames ? allGames.filter(value => value.type === filter_param) : [];

    if (filter_param === 'search' && searchGame) {
        filteredGames = allGames.filter(item => item.name.toLowerCase().includes(searchGame.toLowerCase()));
    }

    if (filter_param === 'provider' && filterProvider) {
        filteredGames = allGames.filter(item => item.provider === filterProvider);
    }

    if (filter_param === 'categorySearch' && filterCategory){
        filteredGames = allGames.filter(item => item.type === filterCategory);
    }

    const showMoreGames = () => {
        setVisibleGames(prev => prev + 8);
    };

    const showAllGames = () => {
        setVisibleGames(filteredGames.length);
    };

    if(filteredGames.length === 0 && filter_param === 'categorySearch'){
        return (<li className="game-list-container">
            <div className="title-box">
                <div className="filter-box">
                    <div className="filter-icon">{filterIcon}</div>
                    <div className="filter-name">{filterName}</div>
                </div>
                {language === 'rus' ?
                    <button className="show-all" onClick={showAllGames}>Показать Все</button>
                    :
                    <button className="show-all" onClick={showAllGames}>Show All</button>
                }
            </div>
            {language === 'rus' ?
                <div className="game-boxes">
                    По вашему запросу ничего не найдено
                </div>
                :
                <div className="game-boxes">
                    According to your request, nothing was found
                </div>
            }
        </li>)
    }

    if (filteredGames.length !== 0) {
        return (
            <li className="game-list-container">
                <div className="title-box">
                    <div className="filter-box">
                        <div className="filter-icon">{filterIcon}</div>
                        <div className="filter-name">{filterName}</div>
                    </div>
                    {language === 'rus' ?
                        <button className="show-all" onClick={showAllGames}>Показать Все</button>
                        :
                        <button className="show-all" onClick={showAllGames}>Show All</button>
                    }
                </div>
                <div className="game-boxes">
                    {filteredGames.slice(0, visibleGames).map((value, index) => (
                        <Game key={index} game={value} />
                    ))}
                </div>
                {filteredGames.length > visibleGames && (
                    <div className="show-more-container">
                        <button className="show-more" onClick={showMoreGames}>
                            <div className="icon">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.167 13.8333L13.0003 12.6458L9.16699 16.4792L5.33366 12.6458L4.16699 13.8333L9.16699 18.8333L14.167 13.8333ZM15.8337 2.16666L12.5003 2.16666C11.3475 2.16666 10.3649 2.57291 9.55241 3.38541C8.73991 4.19791 8.33366 5.18055 8.33366 6.33332L8.33366 11.4792L5.33366 8.47916L4.16699 9.66666L9.16699 14.6667L14.167 9.66666L13.0003 8.47916L10.0003 11.4792L10.0003 6.33332C10.0003 5.63888 10.2434 5.0486 10.7295 4.56249C11.2156 4.07638 11.8059 3.83332 12.5003 3.83332L15.8337 3.83332L15.8337 2.16666Z" fill="white" />
                                </svg>
                            </div>
                            {language === 'rus' ?
                                <div className="text">Показать больше игр</div>
                                :
                                <div className="text">Show more games</div>
                            }
                        </button>
                        {/* {language === 'rus' ?
                            <button className="show-all-adaptive-600px" onClick={showAllGames}>Показать Все</button>
                            :
                            <button className="show-all-adaptive-600px" onClick={showAllGames}>Show All</button>
                        } */}
                    </div>
                )}
            </li>
        );
    } else if (searchGame && filter_param === 'search') {
        return (<li className="game-list-container">
            <div className="title-box">
                <div className="filter-box">
                    <div className="filter-icon">{filterIcon}</div>
                    <div className="filter-name">{filterName}</div>
                </div>
                {language === 'rus' ?
                    <button className="show-all" onClick={showAllGames}>Показать Все</button>
                    :
                    <button className="show-all" onClick={showAllGames}>Show All</button>
                }
            </div>
            {language === 'rus' ?
                <div className="game-boxes">
                    По вашему запросу ничего не найдено
                </div>
                :
                <div className="game-boxes">
                    According to your request, nothing was found
                </div>
            }
        </li>)
    } else return (null)

};

export default Item;
