import React from "react";
import './deposit.css';
import Item from "./Item";
import { ReactComponent as TetherRed } from "../../../../images/icons/tether-red-logo.svg";
import { ReactComponent as Tether } from "../../../../images/icons/tether-normal-logo.svg";
import { ReactComponent as TON } from "../../../../images/icons/ton-logo.svg";
import { ReactComponent as BTC } from "../../../../images/icons/btc-logo.svg";
import { ReactComponent as Doge } from "../../../../images/icons/Doge.svg";
import { ReactComponent as Monero } from "../../../../images/icons/Monero.svg";
import { ReactComponent as TRX } from "../../../../images/icons/TRX.svg";
import { ReactComponent as LTC } from "../../../../images/icons/LTC.svg";
import { ReactComponent as BCH } from "../../../../images/icons/bch-logo.svg";
import { useSelector } from "react-redux";

const DepositList = ({ toPay }) => {
    const { language } = useSelector(state => state.casinoReducer);
    return (
        <ul className="deposit-list">
            {language === 'rus' ? <div className="hint">Выбери валюту</div> : <div className="hint">Choose coin</div>}
            <div className="item-box">
                <Item
                    coinName={'USDT (TRC20)'}
                    fullName={'Tron'}
                    coinLogo={<TetherRed />}
                    currency={'USDT'}
                    network={'TRON'}
                    toPay={toPay}
                />
                <Item
                    coinName={'USDT (ERC20)'}
                    fullName={'Ethereum'}
                    coinLogo={<Tether />}
                    currency={'USDT'}
                    network={'ETH'}
                    toPay={toPay}
                />
                <Item
                    coinName={'BTC'}
                    fullName={'Bitcoin'}
                    coinLogo={<BTC />}
                    currency={'BTC'}
                    network={'BTC'}
                    toPay={toPay}
                />
                <Item
                    coinName={'DOGE'}
                    fullName={'Dogecoin'}
                    coinLogo={<Doge />}
                    currency={'DOGE'}
                    network={'DOGE'}
                    toPay={toPay}
                />
                <Item
                    coinName={'TON'}
                    fullName={'TON'}
                    coinLogo={<TON />}
                    currency={'TON'}
                    network={'TON'}
                    toPay={toPay}
                />
                <Item
                    coinName={'TRX'}
                    fullName={'Tron'}
                    coinLogo={<TRX />}
                    currency={'TRX'}
                    network={'TRON'}
                    toPay={toPay}
                />
                <Item
                    coinName={'XMR'}
                    fullName={'Monero'}
                    coinLogo={<Monero />}
                    currency={'XMR'}
                    network={'XMR'}
                    toPay={toPay}
                />
                <Item
                    coinName={'LTC'}
                    fullName={'Litcoin'}
                    coinLogo={<LTC />}
                    currency={'LTC'}
                    network={'LTC'}
                    toPay={toPay}
                />
                {/* <Item coinName={'BCH'} fullName={'Bitcoin Cash'} coinLogo={<BCH />} /> */}
            </div>
        </ul>
    )
};

export default DepositList;
