const baseURL = 'https://oasis.sendinchat.ru/api/v1';

const urls = {
    getAllGames:(page) => `/game/pag/?page=${page || 1}&page_size=5`,
    startGame: (game_uui, player_id, session_id, tg_id) => `/game/start_game/?game_uui=${game_uui}&player_id=${player_id}&session_id=${session_id || 1}&tg_id=${tg_id}`,
    startDemo: (game_uui, player_id, session_id, tg_id) => `/game/start_demo/?game_uui=${game_uui}&player_id=${player_id}&session_id=${session_id || 1}&tg_id=${tg_id}`,
    createOrGetUser: (tg_chat_id) => `/users/users/?tg_chat_id=${tg_chat_id}`,
    cryptoOut: (user_id, amount, address) => `/crypto/out/?user_id=${user_id}&amount=${amount}&currency=USDT&order_id=000000000102&address=${address}&network=TRON`,
    cryptoIn: (user_id, amount, currency, network) => `/crypto/in/?user_id=${user_id}&amount=${amount}&currency=${currency}&order_id=000000000007&url_return=google.com&network=${network}`,
    getHistory: (user_id) => `/crypto/history/?user_id=${user_id}`,
    getBanners: () => '/banners/banners/',
    getProviders: () => '/game/providers/',
}

export {
    baseURL, urls
}