import React from "react";
import './deposit.css';
import { useDispatch } from "react-redux";
import { casinoAction } from "../../../../redux/slices/casinoSlice";

const Item = ({ coinName, fullName, coinLogo, currency, network, toPay }) => {
    const dispatch = useDispatch();
    return (
        <li className="deposit-item-container" onClick={() => {
            // toggleQR();
            toPay('paymentSum')
            dispatch(casinoAction.setCurrencyAndNetwork({
                currency: currency,
                network: network
            }));
        }}>
            <div className="coin-name">
                <div className="name">{coinName}</div>
                <div className="full-name">{fullName}</div>
            </div>
            <div className="coin-logo">{coinLogo}</div>
        </li>
    )
};

export default Item;
