import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import './gameList.css';
import { casinoAction } from "../../redux/slices/casinoSlice";

const CardGame = ({ game }) => {
    const { image, uuid, provider, name } = game

    const { user_api, language } = useSelector(state => state.casinoReducer)

    let dispatch = useDispatch();

    return (
        <div className={`game blue`} style={{ 'backgroundImage': `url('${image}')` }}>
            <div className='title-box'>
                <div className='game-title'>{name}</div>
                <div className='company-title'>{provider}</div>
            </div>
            <div className='btn-box'>
                {language === 'rus' ?
                    <button className='play-btn' onClick={() => dispatch(casinoAction.startGame([uuid, user_api.user_id, 1, user_api.tg_chat_id]))}>Играть</button>
                    :
                    <button className='play-btn' onClick={() => dispatch(casinoAction.startGame([uuid, user_api.user_id, 1, user_api.tg_chat_id]))}>Play</button>
                }
                {language === 'rus' ?
                    <button className='demo-btn' onClick={() => dispatch(casinoAction.startDemo([uuid, user_api.user_id, 1, user_api.tg_chat_id]))}>Демо</button>
                    :
                    <button className='demo-btn' onClick={() => dispatch(casinoAction.startDemo([uuid, user_api.user_id, 1, user_api.tg_chat_id]))}>Demo</button>
                }
            </div>
            {/* <div className="game-title-shown">{name}</div> */}
            <div className='hover-backround'><img /></div>
        </div>
    );
};

export default CardGame;
