import React from "react";
import './navigation.css';
import { useSelector } from "react-redux";
import useDecimalAdjust from "CustomHooks/useDecimalAdjust";

const Navigation = ({ swapList, currentList }) => {
    const { user_api, currency, language } = useSelector(state => state.casinoReducer);
    const editedBalance = useDecimalAdjust("floor", user_api.balance / 100, -2);
    return (
        <div className="modal-navigation">
            <div className="current-balance">
                {user_api.balance ? editedBalance : '0.00'} {currency === "RUB" ? "₽" : "$"}
            </div>
            <div className="swipper">
                {language === 'rus' ?
                    <button onClick={() => swapList('deposit')}
                        className={`${currentList === 'deposit' ? 'active' :
                            currentList === 'paymentSum' ? 'active' : null}`}>Пополнить</button>
                    :
                    <button onClick={() => swapList('deposit')}
                        className={`${currentList === 'deposit' ? 'active' :
                            currentList === 'paymentSum' ? 'active' : null}`}>Deposit</button>
                }
                {language === 'rus' ?
                    <button onClick={() => swapList('withdraw')}
                        className={`${currentList === 'withdraw' ? 'active' :
                            currentList === 'withdrawSum' ? 'active' : null}`}>Вывести</button>
                    :
                    <button onClick={() => swapList('withdraw')}
                        className={`${currentList === 'withdraw' ? 'active' :
                            currentList === 'withdrawSum' ? 'active' : null}`}>Withdraw</button>
                }
                {language === 'rus' ?
                    <button onClick={() => swapList('history')}
                        className={`${currentList === 'history' ? 'active' : 'null'}`}>История</button>
                    :
                    <button onClick={() => swapList('history')}
                        className={`${currentList === 'history' ? 'active' : 'null'}`}>History</button>
                }


            </div>
        </div>
    )
};

export default Navigation;
