import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from 'crypto-js';
import { useLocation } from "react-router-dom";

import css from "./main.module.css";
// import { Loader } from "./Components/Loader/Loader";
import { casinoAction } from "../../redux/slices/casinoSlice";
// import CardGame from "../../Components/CardGame/CardGame";
import GameList from "Components/GameList/GameList";
import Loader from "../../Components/NewLoader/Loader";
import Search from "Components/Search/Search";
import Slider from "Components/Slider/Slider";
import ChatIcon from "Components/ChatIcon/ChatIcon";
import Language from "Components/Language/Language";
import GameFrame from "Components/GameFrame/GameFrame";
import Anchor from "Components/Anchor/Anchor";

const MainPage = () => {

    const location = useLocation();
    const tg_id = new URLSearchParams(location.search).get('tg_id')
    const hash = new URLSearchParams(location.search).get('hash')
    const auth_date = new URLSearchParams(location.search).get('auth_date')


    const createAuthHMACSignatureFromObject = (tgId, authDate, privateKey) => {
        // Соберем данные для подписи
        const dataToSign = tgId + authDate;

        const bytedData = CryptoJS.enc.Utf8.parse(dataToSign);
        const bytedKey = CryptoJS.enc.Utf8.parse(privateKey);

        // Создадим HMAC SHA-256 подпись
        const hmacSignature = CryptoJS.HmacSHA256(bytedData, bytedKey);

        // Преобразуем подпись в строку в формате Base64
        return CryptoJS.enc.Base64.stringify(hmacSignature);
    };

    const privateKey = process.env.REACT_APP_PRIVATE_KEY;

    const hash_ = createAuthHMACSignatureFromObject(tg_id, auth_date, privateKey);


    const dispatch = useDispatch();

    const { user_api, isLoading, gameUrl } = useSelector(state => state.casinoReducer)

    const [page, setPage] = useState(1)

    useEffect(() => {
        if (hash_ === hash) {
            dispatch(casinoAction.auth({ tg_chat_id: tg_id }))
        }
    }, [dispatch, page, tg_id, hash, hash_, privateKey])

    useEffect(() => {
        dispatch(casinoAction.getAllGames(page))
    }, [dispatch, page])
    const [showAnchor, setShowAnchor] = useState(false)
    useEffect(() => {
        const handleScrollButtonVisibity = () => {
            window.pageYOffset > 50 ? setShowAnchor(true) : setShowAnchor(false)
        }
        window.addEventListener('scroll', handleScrollButtonVisibity);
        return () => {
            window.removeEventListener('scroll', handleScrollButtonVisibity)
        }
    }, [])
    return (
        <div className={"App"}>
            {/*<div>privateKey {JSON.stringify(privateKey)}</div>*/}
            {/*<div>tg_id {JSON.stringify(tg_id)}</div>*/}
            {/*<div>hash {JSON.stringify(hash)}</div>*/}
            {/*<div>hash_ {JSON.stringify(hash_)}</div>*/}
            {/*<div>auth_date {JSON.stringify(auth_date)}</div>*/}
            {/*<div>user_api {JSON.stringify(user_api)}</div>*/}
            <Slider />
            <Search />
            <ChatIcon />
            {showAnchor ? <Anchor /> : null}
            {/* <Language /> */}
            <GameList />
            {
                isLoading ? <Loader frame={false} /> : null
            }
            {gameUrl ? <GameFrame /> : null}
        </div>
    );
};

export default MainPage;
