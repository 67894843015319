import React, { useRef, useState } from "react";
import './choosePaymentSum.css';
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../../../redux/slices/casinoSlice";

const ChoosePaymentSum = () => {
    const dispatch = useDispatch();
    const { user_api, currency, network, language } = useSelector(state => state.casinoReducer);

    const [inputError, setInputError] = useState(false);
    const inputAmountRef = useRef('');

    const [trigger, setTrigger] = useState(null)

    const handleButtonClick = (amount) => {
        inputAmountRef.current.value = amount;
        setInputError(false);
        setTrigger(!trigger)
        console.log(trigger);
    };

    const handlePayment = () => {
        const inputValue = inputAmountRef.current.value;
        if (inputValue) {
            dispatch(casinoAction.cryptoIn([user_api.user_id, inputValue, currency, network]));
        } else {
            setInputError(true);
        }

    };

    return (
        <div className="choose-payment-sum-container">
            {language === 'rus' ? <div className="hint">Сумма пополнения</div> : <div className="hint">Deposit Sum</div>}
            <div className="input-box">
                <input ref={inputAmountRef} type="number" placeholder={language === 'rus' ? "Минимум - 2$" : "Minimum - 2$"}
                    onChange={() => { setInputError(false); setTrigger(!trigger) }} />
                <div className="coin">{currency}</div>
            </div>
            {inputError ?
                language === 'rus' ?
                    <div className="error">Обязательное поле</div>
                    :
                    <div className="error">This field is required</div>
                :
                null}
            <div className="popular-sum">
                <button className={inputAmountRef.current.value === '5' ? 'active' : ''} onClick={() => handleButtonClick(5)}>5$</button>
                <button className={inputAmountRef.current.value === '20' ? 'active' : ''} onClick={() => handleButtonClick(20)}>20$</button>
                <button className={inputAmountRef.current.value === '50' ? 'active' : ''} onClick={() => handleButtonClick(50)}>50$</button>
                <button className={inputAmountRef.current.value === '100' ? 'active' : ''} onClick={() => handleButtonClick(100)}>100$</button>
                <button className={inputAmountRef.current.value === '200' ? 'active' : ''} onClick={() => handleButtonClick(200)}>200$</button>
                <button className={inputAmountRef.current.value === '500' ? 'active' : ''} onClick={() => handleButtonClick(500)}>500$</button>
            </div>
            {language === 'rus' ?
                <div className="fee">Приблизительная комиссия - 0,2 $</div>
                :
                <div className="fee">Approximate commission - 0,2 $</div>
            }
            {language === 'rus' ?
                <button className="lets-pay" onClick={handlePayment}>Перейти к оплате</button>
                :
                <button className="lets-pay" onClick={handlePayment}>Let's pay</button>
            }
        </div>
    );
};

export default ChoosePaymentSum;
