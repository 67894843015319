import React, { useEffect, useRef, useState } from "react";
import css from './slider.module.css';
import Banner1 from '../../images/banner1.png';
import Banner2 from '../../images/banner2.png';
import Banner3 from '../../images/banner3.png';
import { useDispatch, useSelector } from "react-redux";
import { casinoAction } from "../../redux/slices/casinoSlice";
import { ReactComponent as PrevArrow } from "../../images/icons/prev-icon.svg";
import { ReactComponent as NextArrow } from "../../images/icons/next-icon.svg";


const Slider = () => {
    const delay = 5000;
    // const {banners} = useSelector(state => state.casinoReducer)
    const banners = [{ photo: Banner1 }, { photo: Banner2 }, { photo: Banner3 }];

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(casinoAction.getBanners())
    }, [])

    const [index, setIndex] = useState(0);
    const touchStartX = useRef(null);
    const touchEndX = useRef(null);
    const timeoutRef = useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    function handleTouchStart(e) {
        touchStartX.current = e.touches[0].clientX;
    }

    function handleTouchMove(e) {
        touchEndX.current = e.touches[0].clientX;
    }

    function handleTouchEnd() {
        if (touchStartX.current < touchEndX.current) {

            setIndex((prevIndex) =>
                prevIndex === 0 ? banners.length - 1 : prevIndex - 1
            );
        } else if (touchStartX.current > touchEndX.current) {

            setIndex((prevIndex) =>
                prevIndex === banners.length - 1 ? 0 : prevIndex + 1
            );
        }

        resetTimeout();
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
            () =>
                setIndex((prevIndex) =>
                    prevIndex === banners.length - 1 ? 0 : prevIndex + 1
                ),
            delay
        );

        return () => {
            resetTimeout();
        };
    }, [index, banners]);

    return (
        <div
            className={css.slideshow}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div
                className={css.slideshowSlider}
                style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
            >
                {banners.map((banner, index) => (
                    <div
                        className={css.slide}
                        key={index}
                        style={{ "backgroundImage": `url(${banner.photo})` }}
                    ></div>
                ))}
            </div>
            <div className={css.arrow} onClick={() => setIndex((prevIndex) =>
                prevIndex === 0 ? banners.length - 1 : prevIndex - 1
            )}>
                <PrevArrow />
            </div>
            <div className={css.arrow} onClick={() => setIndex((prevIndex) =>
                prevIndex === banners.length - 1 ? 0 : prevIndex + 1
            )}>
                <NextArrow />
            </div>
            <div className={css.slideshowDots}>
                {banners.map((_, idx) => (
                    <div
                        key={idx}
                        className={`${css.slideshowDot} ${index === idx ? css.active : ""}`}
                        onClick={() => {
                            setIndex(idx);
                        }}
                    >
                        <div className={css.slideshowActiveBar}></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Slider;